<template>
  <div class="login-container minSize">
    <Header></Header>
    <Back @handleBack="handleBack"></Back>
    <div class="login-form">
      <div class="login-form-content">
        <div class="title">{{$t('login.findPwsd')}}</div>
        <div class="login-item">
          <div class="errorTip errorTip2">{{errorTip}}</div>
        </div>
        <div class="login-item email">
          <el-input 
            v-model="myUserName.value" 
            @input="onKeyupUserName"
            :placeholder="$t('login.phone')"
            clearable
          ></el-input>
        </div>
        <div class="login-item password">
          <el-input 
            v-model="myCode.value" 
            @input="codeOnlyNumber"
            :placeholder="$t('login.inputCode')"
            :maxlength="4"
            auto-complete="new-password"
            clearable
          ></el-input>
          <el-button :class="btnDisabled ? 'disabled_style' : 'btn_style'"  type="primary" @click="handleAuthCode" :disabled="btnDisabled">
          {{btntxt}}
          </el-button>
        </div>
        <div class="login-item password">
          <el-input 
            v-model="myUserNewPwd.value" 
            :placeholder="$t('login.surePwd')"
            clearable
            :minlength='8'
            :maxlength='16'
            show-password
          ></el-input>
        </div>
        <div class="login-item password">
          <el-input 
            v-model="myUserNewPwd2.value" 
            :placeholder="$t('seeting.inputAgainPwd')"
            clearable
            :minlength='8'
            :maxlength='16'
            show-password
        ></el-input>
        </div>
          <el-button :class="handleCheck ? 'no_login':'login_style'"  :disabled="handleCheck" @click="handleFinish">{{$t('login.complete')}}</el-button>
      </div>
    </div>
    <Footer></Footer>
      <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :visible.sync="commonElDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{ commonElDialogTitle }}</div>
      </div>

      <div class="dialog-content">
        {{ commonElDialogText }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonElDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import {checkCode,checkMobile,checkchangePwd} from "@/utils/check";
import { sendCode, forgetPassword } from '@/api/meet'

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Back from "@/components/back/Back";

Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.component("Back", Back);

export default {
  data() {
    return {
      commonElDialog:false,
      commonElDialogTitle: '',
      commonElDialogText: '',
      // 用户名称
      myUserName: {
        value: "",
        type: "text",
      },
      checkCode: "",
      loginType: "enterprise", // 默认企业登录
      auth: false, // 默认个人密码登录
      // 密码输入框
      myPassword: {
        value: "",
        type: "password",
      },
      // 验证码
      myCode: {
        value: "",
        type: "text",
      },
      btntxt: this.$t('login.getCode'),
      btnDisabled: true,
      errorTip: "", // 错误信息提示
      // 用户新密码
      myUserNewPwd: {
        value: "",
        type: "password",
      },
      // 用户新密码再次
      myUserNewPwd2: {
        value: "",
        type: "password",
      },
    };
  },
  mounted() {
  },
  computed: {
    handleCheck() {
      if (
        this.myUserName.value &&
        this.myCode.value &&
        this.myUserNewPwd.value &&
        this.myUserNewPwd2.value
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    codeOnlyNumber(value) {
      this.myCode.value = value.replace(/[^\d]/g, ""); 
    },
    // 返回按钮s
    handleBack() {
      // this.$router.back();
      this.$router.push({ name: "login" });
    },
    // 验证码登录
    handleAuth() {
      this.auth = !this.auth;
    },
    // 获取验证码
    async handleAuthCode() {
      let that = this;
      if (!checkMobile(that.myUserName.value)) {
        that.commonElDialogText = that.$t('login.try')
        that.commonElDialogTitle = that.$t('login.faildCode')
        that.commonElDialog = true
        return
      }
      let data = {
        phone: this.myUserName.value,
      }

      that.getCode();
      try {
        await sendCode(data)

        this.$message({
          // message: '验证码已发送',
          message: this.$t('login.successCode'),
          type: 'success',
          customClass: 'send-msg-tip'
        });
        that.getCode();
      } catch (error) {
        // console.log(error) // TODO:
      }
    },
     onKeyupUserName() {
      this.btnDisabled = this.myUserName.value === "";
    },
    // 验证码倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnDisabled = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count = this.count - 1;
            this.btntxt = this.count + this.$t('login.resend')
          } else {
            this.btnDisabled = false;
            clearInterval(this.timer);
            this.btntxt = this.$t('login.getCode');
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 点击完成
    async handleFinish() {
      let that = this;
      if (that.myUserNewPwd.value.length < 8 && that.myUserNewPwd2.value.length < 8) {
          that.commonElDialogTitle = that.$t('login.tips')
          that.commonElDialogText = that.$t('reg.lengthPwd')
          that.commonElDialog = true
          return
      }
      if (that.myUserNewPwd.value !== that.myUserNewPwd2.value) {
          that.commonElDialogTitle = that.$t('login.tips')
          that.commonElDialogText = that.$t('reg.somePwd')
          that.commonElDialog = true
          return
      }
      let data = {};
      data.password = Base64.stringify(
        CryptoJS.enc.Utf8.parse(sha256(that.myUserNewPwd.value).toString())
      );
      data.username = that.myUserName.value;
      data.checkCode = that.myCode.value;

      try {
        await forgetPassword(data)

        that.$router.push("/finish");
      } catch (error) {
        // console.log(error) TODO:
      }
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .el-input__inner{
    padding:0;
    border:none;
    border-radius: 0;
    border-bottom: 1px solid #dcdfe6;
    color: #333333;
  }
  ::v-deep .el-button--default{
      height: 36px;
      padding:0 10px;
      font-size: 14px;
      color: #fff;
      background-color: #1ab370;
      border-radius: 4px;
      &:hover{
        background: #3BBF82;
      }
      &:focus{
        background: #0E8C5A;
      }
    }
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.login-form {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 1 1 auto;
  //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .login-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .title {
      display: inline-block;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
    }

    .login-item {
      width: 360px;
      height: 40px;
      position: relative;
      ::v-deep .input-text-parent .eyes{
          right: 32px;
        }
        ::v-deep .input-text-parent .input-box{
           padding: 0 20px 0 4px;
        }
        ::v-deep .yb-button{
          width:114px;
          height:30px;
          margin-bottom:5px;
        }
        ::v-deep .el-input__inner::placeholder{
          color: #999999;
          font-size: 14px;
        }
      input {
        width: 100%;
        height: 100%;
        border: none;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      input:focus {
        outline: none;
        border-color: #1ab370;
      }
    }

    .email {
      input {
        border-bottom: 1px solid #e5e5e5;
      }
    }

    .password {
      margin-top: 16px;
      input {
        border-bottom: 1px solid #e5e5e5;
        position: relative;
      }
      ::v-deep .el-button{
        padding:0;
      }
      @mixin public-code(){
        position: absolute;
        right: 0;
        bottom: 4px;
        height: 30px;
        border-radius: 4px;
        padding: 6px 10px;
        font-family: PigFangSC-Regular, PingFang SC;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
      }
      .btn_style{
        min-width: 90px;
        width: auto;
        background: rgba(255, 255, 255);
        border: 1px solid #FF8200;
        color:#FF8200;
        @include public-code;
        &:focus{
          background: rgba(255, 130, 0, 0.1);
        }
        &:hover{
          background: rgba(255, 130, 0, 0.2);
        }
      }
      .disabled_style{
        border:none;
        background: #FFDFA3;
        color: #FFFFFF;
        @include public-code;
      }
    }
      @mixin public_style($bg){
        display:inline-block;
        width: 360px;
        margin-top: 40px;
        height: 40px;
        padding:0 10px;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        background-color: $bg;
      }
      .no_login{
        @include public_style(#b5e6ca);
      }
      .login_style{
        @include public_style(#1ab370);
        &:hover{
          background: #3BBF82;
        }
        &:focus{
          background: #0E8C5A;
        }
      }
  }
}
.errorTip2 {
  text-indent: 15px;
}
</style>
